import React from 'react'
import { Input } from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const { Search } = Input;

const TableTop = ({ title, link, handleSearch }) => {

  return (
    <div className="fw fl">
      <div className="w50p w80p_550 w90p_400 fl">
        <p className="listing_title_1 fl mr20 mr5_320">
          {title}
        </p>
        <div className="w60p w50p_320 fl listing_search">
          <Search
            placeholder="Search..."
            onSearch={handleSearch}
            onChange={(e) => handleSearch(e.target.value)}
            enterButton
            allowClear
          />
        </div>
      </div>
      <div className="w50p w20p_550 w10p_400 fl">
        <Link to={link} className="add_plus">
          <span className="add_plus_symbol">+</span>
        </Link>
      </div>
    </div>
  )

}

TableTop.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
}

export default TableTop;




