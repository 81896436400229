
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Select } from 'antd';
const baseURL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");
const is_superuser = localStorage.getItem("is_superuser")

const { Option } = Select

const MultiSelectAutoComplete = ({ name, model,  setValue, value_main }) => {

    const [options, setOptions] = useState([])

    useEffect(() => {
            axios.get(
                `${baseURL}/${model}/api/search/`, 
                {
                    params: { q: '' },
                    headers: {
                        Authorization: `Token ${token}` 
                    }
                }
            )
            .then((response) => {
                const data = response.data

                console.log(data)

                setOptions(data.map(item => ({
                    id: item.id,
                    label: `
                    ${item.customer_number}
                    ${item.name}
                    ${item.phone_1}
                    ${item.email_1}
                    `  
                })).slice(0, 10))
            })
            .catch((error) => {
                console.log(error)
            })

    }, [name, model,  setValue, value_main])  

    const handleSearch = async (inputValue) => {
        if (inputValue) {
            try {

                const response = await axios.get(
                    `${baseURL}/${model}/api/search/`, 
                    {
                        params: { q: inputValue },
                        headers: {
                            Authorization: `Token ${token}` 
                        }
                    }
                )

                const data = response.data
                console.log(data)

                setOptions(data.map(item => ({
                    id: item.id,
                    label: `
                    ${item.customer_number}
                    ${item.name}
                    ${item.phone_1}
                    ${item.email_1}
                    `  
                })).slice(0, 10))
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setOptions([{id: 1, label: 'No data found!'}]);
            }
        } else {
            setOptions([]);
        }
    }

    const handleChange = (selectedValues) => {
        setValue(selectedValues)
    }

    return (
        <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={`Select ${name}`}
            onSearch={handleSearch}
            onChange={handleChange}
            showSearch
            filterOption={false}
            notFoundContent={null}
            value={value_main}
        >
            {options.map(o => (
                <Option key={o.id} value={o.id}>
                    {o.label}
                </Option>
            ))}
        </Select>
    );
};

export default MultiSelectAutoComplete;












