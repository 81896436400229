
export const TimeOpenClose = () => {
    const intervals = [];
    const startMinutes = 6 * 60; 
    const endMinutes = 24 * 60; 
    
    for (let currentTime = startMinutes; currentTime < endMinutes; currentTime += 15) {
      const hours24 = Math.floor(currentTime / 60);
      const minutes = currentTime % 60;
      

      const hours12 = hours24 % 12 || 12;
      const ampm = hours24 < 12 ? 'AM' : 'PM';
      

      const formattedTime = `${String(hours12).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${ampm}`;
      intervals.push(formattedTime);
    }
  
    return intervals;
}
  