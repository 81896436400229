// AddressInput.js
import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

const AddressInputDelivery = ({ gmapApiKey, dataAll, setDataAll }) => {
  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setDataAll(prevDataAll => ({
      ...prevDataAll,
      [name]: value
    }));
  };

  const { ref: deliveryAddress } = usePlacesWidget({
    apiKey: gmapApiKey,
    onPlaceSelected: (place) => {
      if (place && place.address_components) {


        setDataAll((prevDataAll) => {
          const updatedDataAlldelivery = { ...prevDataAll }
  
          updatedDataAlldelivery.delivery_street_address = "" 

          place.address_components.forEach(component => {
            const types = component.types;
  
            if (types.includes('suite') || types.includes('apartment') || types.includes('floor')) {
              updatedDataAlldelivery.delivery_unit_suite = component.long_name;
            } else if (types.includes('street_number')) {
              updatedDataAlldelivery.delivery_street_address += component.long_name + " ";
            } else if (types.includes('route')) {
              updatedDataAlldelivery.delivery_street_address += component.long_name;
            } else if (types.includes('locality')) {
              updatedDataAlldelivery.delivery_city = component.long_name;
            } else if (types.includes('administrative_area_level_1')) {
              updatedDataAlldelivery.delivery_province_state = component.long_name;
            } else if (types.includes('postal_code')) {
              updatedDataAlldelivery.delivery_postal_code = component.long_name;
            } else if (types.includes('country')) {
              updatedDataAlldelivery.delivery_country = component.long_name;
            }
          });
  
          if (place.geometry && place.geometry.location) {
            updatedDataAlldelivery.delivery_latitude = place.geometry.location.lat();
            updatedDataAlldelivery.delivery_longitude = place.geometry.location.lng();
          }
  
          return updatedDataAlldelivery;
        });

        
      }
    },
    options: {
      types: ['address'],
    },
  })

  return (

    <input 
        className="rel fw fl" 
        ref={deliveryAddress} 
        value={dataAll.delivery_street_address || ''} 
        name="delivery_street_address" 
        type="text" 
        onChange={handleAddressChange}  />
  );
};

export default AddressInputDelivery;





