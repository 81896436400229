import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../../../global/components/common/Footer";
import { Link } from "react-router-dom"

const Login = ({ authenticateUser }) => {

  const baseURL = process.env.REACT_APP_API_URL;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/api/login/`, {
        username,
        password,
      });

      const token = response.data.token
      const is_superuser = response.data.is_superuser
      const group_names = response.data.group_names

      localStorage.setItem("token", token)
      localStorage.setItem("is_superuser", is_superuser)
      localStorage.setItem("group_names", group_names)


      if (group_names.includes('data_entry_clinic')) {
        window.location.href = "/region-clinics";
      } else {
        window.location.href = "/";
      }

      
    } catch (error) {
      setError("Invalid username or password");
      console.error(error);
    }
  };

  useEffect(() => {
    document.title = "Login | 1db";

  }, []);


  return (

    <>

    <div className="fw fl h85vh_m">

      <div className="fw fl mt10 ml20 mb120 mb30_767 tc_767 ml0_767">
        <Link to="/">
            <span className="Bauhaus_Medium_BT fs60 lh60">1db.ca</span>
        </Link>
      </div>




      <div className="continer">
        <div className="row">
          <div className="col-md-6 offset-md-3">

            <div className="fw fl mt60 mt30_767">

              <div className="w35p fl mr5p fw_767 tc_767 mt20">
                  <p className="fs50 lh50 mb20 fs25_767 lh25_767">Sign in</p>
                  <p className="fs18 lh18">Get started today!</p>
              </div>            

              <div className="w60p fl fw_767">

                <div className="fw fl w80p_767 ml10p_767">

                  <form onSubmit={handleSubmit} className="">

                    <div className="fw fl mb10">
                    <p className="fw fl mb0">Username</p>
                      <input
                        type="username"
                        className="ge_input fw fl rad4"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                    </div>

                    <div className="fw fl mb10 mt10">
                      <p className="fw fl mb0">Enter Your Password</p>
                      <input
                        type="password"
                        className="ge_input fw fl rad4"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>

                    <div className="fw fl">
                      {error && <p className="text-red-500 text-sm">{error}</p>}
                    </div>


                    <div className="fw fl tl">
                      <button type="submit"
                        className="ge_input_login "
                      >
                        Sign in
                      </button>
                    </div>

                  </form>

                </div>

              </div>

            </div>

          </div>
        </div>
      </div>


      </div>

    <Footer/>

    </>


  );
};

export default Login;
