
import dayjs from 'dayjs';


export const disableDate = (current) => {
  return current && current.isAfter(dayjs().subtract(18, 'year'), 'day');
}

export const disableFutureDates = (current) => {
  return current && current.isAfter(dayjs().endOf('day'));
}






