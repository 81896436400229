// AddressInput.js
import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

const AddressInput = ({ gmapApiKey, dataAll, setDataAll }) => {
  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setDataAll(prevDataAll => ({
      ...prevDataAll,
      [name]: value
    }));
  };

  const { ref: mainAddress } = usePlacesWidget({
    apiKey: gmapApiKey,
    onPlaceSelected: (place) => {
      if (place && place.address_components) {
        setDataAll((prevDataAll) => {
          const updatedDataAll = { ...prevDataAll };

          updatedDataAll.street_address = "";

          place.address_components.forEach(component => {
            const types = component.types;

            if (types.includes('suite') || types.includes('apartment') || types.includes('floor')) {
              updatedDataAll.unit_suite = component.long_name;
            } else if (types.includes('street_number')) {
              updatedDataAll.street_address += component.long_name + "  ";
            } else if (types.includes('route')) {
              updatedDataAll.street_address += component.long_name;
            } else if (types.includes('locality')) {
              updatedDataAll.city = component.long_name;
            } else if (types.includes('administrative_area_level_1')) {
              updatedDataAll.province_state = component.long_name;
            } else if (types.includes('postal_code')) {
              updatedDataAll.postal_code = component.long_name;
            } else if (types.includes('country')) {
              updatedDataAll.country = component.long_name;
            }
          });

          if (place.geometry && place.geometry.location) {
            updatedDataAll.latitude = place.geometry.location.lat();
            updatedDataAll.longitude = place.geometry.location.lng();
          }

          return updatedDataAll;
        });
      }
    },
    options: {
      types: ['address'],
    },
  });

  return (
    <input
      ref={mainAddress}
      value={dataAll.street_address || ''}
      name="street_address"
      type="text"
      onChange={handleAddressChange}
    />
  );
};

export default AddressInput;





