import React from 'react';
import { Dropdown, Pagination } from 'antd';
import { DownOutlined } from '@ant-design/icons';


const PaginationDropdown = ({ page, pageSize, total, setPage, setPageSize, menu }) => {
  return (
    <div className="fw fl mt20 mb20">
      <div className="pagination-dropdown-container">
        <Dropdown overlay={menu} trigger={['click']}>
          <p 
            className="pagination_dd"               
            onClick={(e) => e.preventDefault()}
          >
            {`${pageSize}`} <DownOutlined />
          </p>
        </Dropdown>

        <Pagination
          current={page}
          pageSize={pageSize}
          total={total}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          showSizeChanger={false} 
          showQuickJumper
          showTotal={(total, range) => `Total ${total} items`} 
        />
      </div>
    </div>
  );
};

export default PaginationDropdown;



