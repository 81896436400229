import React from 'react';

function RadioButtonNormal({name, value, checked, onChange }) {
  return (
    <label className="radiocontainer fr">
      {value === "false" ? 'No' : 'Yes'}
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="radiocheckmark"></span>
    </label>
  );
}

export default RadioButtonNormal;
