import React from 'react';
import RadioButton from './RadioButton'; 

function RadioButtonGroup({ label, name, selectedValue, onChange, leftw, rightw }) {
  return (
    <div className="fw fl">
      <span className={`fl ${leftw}`}>{label}</span>
      <span className={`fr ${rightw}`}>
        <RadioButton
          id={`${name}_true`}  
          name={name}
          value="true" 
          checked={selectedValue === true}
          onChange={onChange}
        />
        <RadioButton
          id={`${name}_false`}
          name={name}
          value="false" 
          checked={selectedValue === false}
          onChange={onChange}
        />
      </span>
    </div>
  );
}

export default RadioButtonGroup;
