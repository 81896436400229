

  export const HandleDeliveryAddress = (e, setState) => {
    const { checked } = e.target;

    console.log("Checkbox is checked:", checked);


    setState(prevData => ({
      ...prevData,
      is_delivery_address_different: checked
    }));
  };



