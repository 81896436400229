import React from 'react'
import RadioButtonNormal from './RadioButtonNormal'

function RadioButtonGroupNormal({ label, name, selectedValue, onChange }) {
  return (
    <div className="fw fl">
      <span className="fl w60p mr5p">{label}</span>
      <span className="fr w35p">
        <RadioButtonNormal
          id={`${name}_false`}
          name={name}
          value="false" 
          checked={selectedValue === false}
          onChange={onChange}
        />
        <RadioButtonNormal
          id={`${name}_true`}
          name={name}
          value="true" 
          checked={selectedValue === true}
          onChange={onChange}
        />
      </span>
    </div>
  );
}

export default RadioButtonGroupNormal;
