import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate} from 'react-router-dom'
import Navbar from "../../../global/components/common/NavBar"
import Footer from "../../../global/components/common/Footer"


import DataTable from "react-data-table-component"
import PaginationTableMain from '../../../global/utils/PaginationTableMain'
import { pageSizeOptions } from '../../../global/utils/PageConfig';



const ClinicAll = () => {
  const navigate = useNavigate()

  const baseURL = process.env.REACT_APP_API_URL
  const token = localStorage.getItem("token")
  const is_superuser = localStorage.getItem("is_superuser")

  const [userRole, SetUserRole] = useState("")

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [searchQuery, setSearchQuery] = useState('')
  
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)


  const columns = [
    {
      name: "Customer ID",
      selector: (row) => row.customer_number,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinicindividualdata/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.customer_number}
        </a>
      ),
      sortable: true,
      width: "9%",
    },
    {
      name: "Clinic Name",
      selector: (row) => row.clinic_name,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinicindividualdata/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.clinic_name}
        </a>
      ),
      sortable: true,
      width: "22%",
      textAlign: "right",
    },
    {
      name: "City",
      selector: (row) => row.city,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinicindividualdata/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.city}
        </a>
      ),
      sortable: true,
      width: "10%",
      textAlign: "right",
    },
    {
      name: "Province",
      selector: (row) => row.province,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinicindividualdata/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.province}
        </a>
      ),
      sortable: true,
      width: "9%",
      textAlign: "right",
    },
    {
      name: "Zip/Postal",
      selector: (row) => row.postal_code,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinicindividualdata/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.postal_code}
        </a>
      ),
      sortable: true,
      width: "8%",
      textAlign: "right",
    },
    {
      name: "Country",
      selector: (row) => row.country_s,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinicindividualdata/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.country}
        </a>
      ),
      sortable: true,
      width: "10%",
      textAlign: "right",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinicindividualdata/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.email}
        </a>
      ),
      width: "21%",
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinicindividualdata/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.phone}
        </a>
      ),
      sortable: true,
      width: "11%",
    },
  ]


  const fetchData = async (page, pageSize, searchQuery) => {

    setLoading(true);
    try {
      const res = await axios.get(`${baseURL}/distributes/api/online_clinics/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          page,
          page_size: pageSize,
          search: searchQuery, 
        },
      })

      const data = res.data.data.results
      const count = res.data.data.count

      setData(data)
      setTotal(count)

    } catch (error) {
      console.error('Error fetching data', error)
    } finally {
      setLoading(false)
    }
  }



  useEffect(() => {
      document.title = "Dentals | Dental Clinics"

      if (!(token && is_superuser === "true")) {
        navigate("/login");
      }

    fetchData(page, pageSize, searchQuery)
  }, [page, pageSize, searchQuery])




  const handlePageChange = (page, itemsPerPage) => {
    setPage(page)
    setPageSize(itemsPerPage)
    console.log(`Current Page: ${page}, Items per Page: ${itemsPerPage}`);
  }
  
  const cnameChange = (e) => {
    setSearchQuery(e.target.value);
  }
  


  return (
    <>
      <Navbar />
      <div className="w98p ml1p fl mt20 h75vh_m">
        <div className='fw fl box-shadow-2 mb5 bg-w p10'>

          <div className="fw fl">


            <div className="fw fl rel">

              {/* <div className="abs right0 top10 zi10">
                  {userRole === '1' && (
                      <span onClick={updateClinicFrom1db} className="updateButton1db">Update</span>
                  )}
              </div> */}

              <input
                type="text"
                placeholder="Search here"
                className="w300 abs left0 zi10 fl  form-control bd"
                onChange={cnameChange}
              />

              { data && data.length > 0 ? (  

                <DataTable
                  className="DatatableOne Datatable_edited"
                  columns={columns}
                  data={data}
                  highlightOnHover
                  subHeader

                />

              ):(
                <div className="fw fl tc mt30 mb30">
                  <p>No data available.</p>
                </div> 
              )
              }

            </div>


          </div>
                
                      
                                            
          { total &&           

            <div className="fw fl mt30 mb100 pl15 pr15">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="fw fl">
                      
                      <PaginationTableMain totalItems={total} pageSize={pageSize} sizeOptions={pageSizeOptions} onPageChange={handlePageChange} />

                    </div>
                  </div>
                </div>
              </div>
            </div>

            }






        </div>
      </div>
      <Footer />
    </>
  );
};

export default ClinicAll;
