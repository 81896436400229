import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const TimezoneSelect = ({name, onTimezoneChange, value }) => {

  const timezones = [
    { value: "(GMT-12:00) International Date Line West", label: "(GMT-12:00) International Date Line West" },
    { value: "(GMT-11:00) Midway Island, Samoa", label: "(GMT-11:00) Midway Island, Samoa" },
    { value: "(GMT-10:00) Hawaii", label: "(GMT-10:00) Hawaii" },
    { value: "(GMT-09:00) Alaska", label: "(GMT-09:00) Alaska" },
    { value: "(GMT-08:00) Pacific Time (US & Canada)", label: "(GMT-08:00) Pacific Time (US & Canada)" },
    { value: "(GMT-08:00) Tijuana, Baja California", label: "(GMT-08:00) Tijuana, Baja California" },
    { value: "(GMT-07:00) Arizona", label: "(GMT-07:00) Arizona" },
    { value: "(GMT-07:00) Chihuahua, La Paz, Mazatlan", label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan" },
    { value: "(GMT-07:00) Mountain Time (US & Canada)", label: "(GMT-07:00) Mountain Time (US & Canada)" },
    { value: "(GMT-06:00) Central America", label: "(GMT-06:00) Central America" },
    { value: "(GMT-06:00) Central Time (US & Canada)", label: "(GMT-06:00) Central Time (US & Canada)" },
    { value: "(GMT-06:00) Guadalajara, Mexico City, Monterrey", label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey" },
    { value: "(GMT-06:00) Saskatchewan", label: "(GMT-06:00) Saskatchewan" },
    { value: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco", label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco" },
    { value: "(GMT-05:00) Eastern Time (US & Canada)", label: "(GMT-05:00) Eastern Time (US & Canada)" },
    { value: "(GMT-05:00) Indiana (East)", label: "(GMT-05:00) Indiana (East)" },
    { value: "(GMT-04:00) Atlantic Time (Canada)", label: "(GMT-04:00) Atlantic Time (Canada)" },
    { value: "(GMT-04:00) Caracas, La Paz", label: "(GMT-04:00) Caracas, La Paz" },
    { value: "(GMT-04:00) Manaus", label: "(GMT-04:00) Manaus" },
    { value: "(GMT-04:00) Santiago", label: "(GMT-04:00) Santiago" },
    { value: "(GMT-03:30) Newfoundland", label: "(GMT-03:30) Newfoundland" },
    { value: "(GMT-03:00) Brasilia", label: "(GMT-03:00) Brasilia" },
    { value: "(GMT-03:00) Buenos Aires, Georgetown", label: "(GMT-03:00) Buenos Aires, Georgetown" },
    { value: "(GMT-03:00) Greenland", label: "(GMT-03:00) Greenland" },
    { value: "(GMT-03:00) Montevideo", label: "(GMT-03:00) Montevideo" },
    { value: "(GMT-02:00) Mid-Atlantic", label: "(GMT-02:00) Mid-Atlantic" },
    { value: "(GMT-01:00) Cape Verde Is.", label: "(GMT-01:00) Cape Verde Is." },
    { value: "(GMT-01:00) Azores", label: "(GMT-01:00) Azores" },
    { value: "(GMT+00:00) Casablanca, Monrovia, Reykjavik", label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik" },
    { value: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London", label: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London" },
    { value: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
    { value: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
    { value: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris", label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris" },
    { value: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb", label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
    { value: "(GMT+01:00) West Central Africa", label: "(GMT+01:00) West Central Africa" },
    { value: "(GMT+02:00) Amman", label: "(GMT+02:00) Amman" },
    { value: "(GMT+02:00) Athens, Bucharest, Istanbul", label: "(GMT+02:00) Athens, Bucharest, Istanbul" },
    { value: "(GMT+02:00) Beirut", label: "(GMT+02:00) Beirut" },
    { value: "(GMT+02:00) Cairo", label: "(GMT+02:00) Cairo" },
    { value: "(GMT+02:00) Harare, Pretoria", label: "(GMT+02:00) Harare, Pretoria" },
    { value: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
    { value: "(GMT+02:00) Jerusalem", label: "(GMT+02:00) Jerusalem" },
    { value: "(GMT+02:00) Minsk", label: "(GMT+02:00) Minsk" },
    { value: "(GMT+02:00) Windhoek", label: "(GMT+02:00) Windhoek" },
    { value: "(GMT+03:00) Kuwait, Riyadh, Baghdad", label: "(GMT+03:00) Kuwait, Riyadh, Baghdad" },
    { value: "(GMT+03:00) Moscow, St. Petersburg, Volgograd", label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
    { value: "(GMT+03:00) Nairobi", label: "(GMT+03:00) Nairobi" },
    { value: "(GMT+03:00) Tbilisi", label: "(GMT+03:00) Tbilisi" },
    { value: "(GMT+03:30) Tehran", label: "(GMT+03:30) Tehran" },
    { value: "(GMT+04:00) Abu Dhabi, Muscat", label: "(GMT+04:00) Abu Dhabi, Muscat" },
    { value: "(GMT+04:00) Baku", label: "(GMT+04:00) Baku" },
    { value: "(GMT+04:00) Yerevan", label: "(GMT+04:00) Yerevan" },
    { value: "(GMT+04:30) Kabul", label: "(GMT+04:30) Kabul" },
    { value: "(GMT+05:00) Yekaterinburg", label: "(GMT+05:00) Yekaterinburg" },
    { value: "(GMT+05:00) Islamabad, Karachi, Tashkent", label: "(GMT+05:00) Islamabad, Karachi, Tashkent" },
    { value: "(GMT+05:30) Sri Jayawardenapura", label: "(GMT+05:30) Sri Jayawardenapura" },
    { value: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
    { value: "(GMT+05:45) Kathmandu", label: "(GMT+05:45) Kathmandu" },
    { value: "(GMT+06:00) Almaty, Novosibirsk", label: "(GMT+06:00) Almaty, Novosibirsk" },
    { value: "(GMT+06:00) Astana, Dhaka", label: "(GMT+06:00) Astana, Dhaka" },
    { value: "(GMT+06:30) Yangon (Rangoon)", label: "(GMT+06:30) Yangon (Rangoon)" },
    { value: "(GMT+07:00) Bangkok, Hanoi, Jakarta", label: "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
    { value: "(GMT+07:00) Krasnoyarsk", label: "(GMT+07:00) Krasnoyarsk" },
    { value: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
    { value: "(GMT+08:00) Kuala Lumpur, Singapore", label: "(GMT+08:00) Kuala Lumpur, Singapore" },
    { value: "(GMT+08:00) Irkutsk, Ulaan Bataar", label: "(GMT+08:00) Irkutsk, Ulaan Bataar" },
    { value: "(GMT+08:00) Perth", label: "(GMT+08:00) Perth" },
    { value: "(GMT+08:00) Taipei", label: "(GMT+08:00) Taipei" },
    { value: "(GMT+09:00) Osaka, Sapporo, Tokyo", label: "(GMT+09:00) Osaka, Sapporo, Tokyo" },
    { value: "(GMT+09:00) Seoul", label: "(GMT+09:00) Seoul" },
    { value: "(GMT+09:00) Yakutsk", label: "(GMT+09:00) Yakutsk" },
    { value: "(GMT+09:30) Adelaide", label: "(GMT+09:30) Adelaide" },
    { value: "(GMT+09:30) Darwin", label: "(GMT+09:30) Darwin" },
    { value: "(GMT+10:00) Brisbane", label: "(GMT+10:00) Brisbane" },
    { value: "(GMT+10:00) Canberra, Melbourne, Sydney", label: "(GMT+10:00) Canberra, Melbourne, Sydney" },
    { value: "(GMT+10:00) Hobart", label: "(GMT+10:00) Hobart" },
    { value: "(GMT+10:00) Guam, Port Moresby", label: "(GMT+10:00) Guam, Port Moresby" },
    { value: "(GMT+10:00) Vladivostok", label: "(GMT+10:00) Vladivostok" },
    { value: "(GMT+11:00) Magadan, Solomon Is., New Caledonia", label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia" },
    { value: "(GMT+12:00) Auckland, Wellington", label: "(GMT+12:00) Auckland, Wellington" },
    { value: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is." },
    { value: "(GMT+13:00) Nuku'alofa", label: "(GMT+13:00) Nuku'alofa" }
  ];

  const handleChange = (value) => {
    onTimezoneChange(name, value);
  };

  return (
    <Select className='fw fl'
      name={name}
      onChange={handleChange}
      placeholder="Select Timezone"
      value={value}
    >
      {timezones.map((tz) => (
        <Option key={tz.value} value={tz.value}>
          {tz.label}
        </Option>
      ))}
    </Select>
  );
};

export default TimezoneSelect;
