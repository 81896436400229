import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";



const PaginationTableMain = ({ totalItems, pageSize, sizeOptions = [], onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(pageSize);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page, itemsPerPage);
  };

  const handleItemsPerPageChange = (e) => {
    const newSize = Number(e.target.value);
    setItemsPerPage(newSize);
    setCurrentPage(1); // Reset to first page
    onPageChange(1, newSize);
  };

  const handleGoToPage = (e) => {
    const page = Number(e.target.value);
    if (page >= 1 && page <= totalPages) {
      handlePageChange(page);
    }
  };

  const generatePaginationNumbers = () => {
    const range = [];
    const maxVisiblePages = 5; 

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= 4; i++) {
          range.push(i);
        }
        range.push('...');
        range.push(totalPages);
      } 
      else if (currentPage >= totalPages - 2) {
        range.push(1);
        range.push('...');
        for (let i = totalPages - 4; i <= totalPages; i++) {
          range.push(i);
        }
      } 

      else {
        range.push(1);
        range.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          range.push(i);
        }
        range.push('...');
        range.push(totalPages);
      }
    }

    return range.filter((item, index) => 
      typeof item === 'number' ? range.indexOf(item) === index : true
    );
  };

  const paginationNumbers = generatePaginationNumbers();

  return (
    <div className="pagination_custom ">

      <div className='pagination_custom_first '>

        <span className='pagination_total_count'>Total {totalItems} items</span>

        <select className='pagination_custom_select_one mr10' value={itemsPerPage} onChange={handleItemsPerPageChange} >
          {sizeOptions.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>

        <span className='pagination_first_span '>
          Page {currentPage} of {totalPages}
        </span>

      </div>


      <div className='pagination_custom_second'>

        <div className='pagination_custom_second_right '>
          <span className='di mr10'>Go to</span>
            <input className='di mr10 pagination_custom_input_one' type="number" min="1" max={totalPages} onChange={handleGoToPage} />
          <span className='di'>Page</span>
        </div>

        <div className='pagination_custom_second_middle'>
          <span onClick={() => handlePageChange(currentPage - 1)} 
          disabled={currentPage === 1} 
          className={`pagination_arrow pagination_left_arrow ${currentPage === 1 ? 'pagination_arrow_inactive' : 'pagination_arrow_active'}`}
          >
            <FontAwesomeIcon icon={faAngleLeft} className='f_icon'  />
          </span>
          {paginationNumbers.map((page, index) => (
            <span key={index} className='pagination_items'>
              {page === '...' ? (
                <span className='pagination_items_inner_dot'>{page}</span>
              ) : (
                <span 
                onClick={() => handlePageChange(page)} 
                disabled={currentPage === page} 
                className={`pagination_items_inner ${currentPage === page ? 'pagination_active_item' : 'pagination_inactive_item'}`}
                >
                  {page}
                </span>
              )}
            </span>
          ))}
          <span onClick={() => handlePageChange(currentPage + 1)} 
          disabled={currentPage === totalPages}
          className={`pagination_arrow pagination_right_arrow ${currentPage === totalPages ? 'pagination_arrow_inactive' : 'pagination_arrow_active'}`}
          >
            <FontAwesomeIcon icon={faAngleRight} className='f_icon' />
          </span>
        </div>

        <div className='pagination_custom_second_left'>
          <span>Total {totalItems} items</span>
        </div>

      </div>



    </div>
  );
};

export default PaginationTableMain;
