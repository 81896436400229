
import React from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const PhoneNumber = ({ value, onChange, ...props }) => {
  return (
    <PhoneInput
      value={value ?? ""}
      onChange={onChange}
      {...props}
    />
  )
}

export default PhoneNumber



