import React, {useState, useEffect} from 'react'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

import verifiedLogo from '../../assets/images/verified.png'

import PhoneNumberDisplay from './PhoneNumberDisplay';

const TableColumnTwo = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);





  const columns = isMobile
    ? [
      { 
        title: 'ID', 
        dataIndex: 'customer_number', 
        key: 'customer_number', 
        width: 100,
        sorter: (a, b) => a.customer_number - b.customer_number 
      },
      {
        title: 'Name',
        dataIndex: 'first_name', 
        key: 'name',
        render: (text, record) => `${record.first_name} ${record.last_name}`,
        sorter: (a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`),
        ellipsis: true,
        width: 180,
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        sorter: (a, b) => a.city.localeCompare(b.city),
        ellipsis: true,
        width: 140,
      },
      {
        title: 'Prov/State',
        dataIndex: 'province_state',
        key: 'province_state',
        sorter: (a, b) => a.province_state.localeCompare(b.province_state),
        ellipsis: true,
        width: 140,
      },
  
      { 
        title: 'Country', 
        dataIndex: 'country', 
        key: 'country', 
        sorter: (a, b) => a.country.localeCompare(b.country),
        ellipsis: true,
        width: 140,
      },
      { title: 'Email', 
        dataIndex: 'email', 
        key: 'email', 
        sorter: (a, b) => a.email.localeCompare(b.email),
        ellipsis: true, 
        width: 220,
      },
      { title: 'Phone', 
        dataIndex: 'phone_mobile', 
        key: 'phone_mobile', 
        sorter: (a, b) => a.phone_mobile.localeCompare(b.phone_mobile),
        ellipsis: true,
        width: 180,
        render: (phone) => (
          <PhoneNumberDisplay value={phone} />
        ),        
      },
      { 
        title: 'Website', 
        dataIndex: 'website', 
        key: 'website', 
        
        sorter: (a, b) => a.website.localeCompare(b.website),
        ellipsis: true,
        width: 200,
      },
      {
        title: 'Active',
        dataIndex: 'is_active',
        key: 'is_active',
        width:80,
        render: (isActive) => (
          <div className="fw fl tc">
            { isActive ? 
            <CheckCircleOutlined style={{ color: 'green' }} /> : 
            <CloseCircleOutlined style={{ color: 'red' }} />
            }
          </div>
        ),
        sorter: (a, b) => a.is_active - b.is_active,
        sortDirections: ['descend', 'ascend'],
      },    
      {
        title: 'Verify',
        dataIndex: 'is_verified',
        key: 'is_verified',
        width:80,
        render: (isActive) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            { isActive &&
                <img src={verifiedLogo} alt="Verified" className='w20 h20' /> 
            }
          </div>
        ),
        sorter: (a, b) => a.is_verified - b.is_verified,
        sortDirections: ['descend', 'ascend'],
      }
      ]
    : [
      { 
        title: 'ID', 
        dataIndex: 'customer_number', 
        key: 'customer_number', 
        width: 100,
        sorter: (a, b) => a.customer_number - b.customer_number 
      },
      {
        title: 'Name',
        dataIndex: 'first_name', 
        key: 'name',
        render: (text, record) => `${record.first_name} ${record.last_name}`,
        sorter: (a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`),
        ellipsis: true,
        style: { minWidth: 100 },
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        sorter: (a, b) => a.city.localeCompare(b.city),
        ellipsis: true,
      },
      {
        title: 'Prov/State',
        dataIndex: 'province_state',
        key: 'province_state',
        sorter: (a, b) => a.province_state.localeCompare(b.province_state),
        ellipsis: true,
      },
  
      { 
        title: 'Country', 
        dataIndex: 'country', 
        key: 'country', 
        sorter: (a, b) => a.country.localeCompare(b.country),
        ellipsis: true,
      },
      { title: 'Email', 
        dataIndex: 'email', 
        key: 'email', 
        sorter: (a, b) => a.email.localeCompare(b.email),
        ellipsis: true, 
      },
      { title: 'Phone', 
        dataIndex: 'phone_mobile', 
        key: 'phone_mobile', 
        sorter: (a, b) => a.phone_mobile.localeCompare(b.phone_mobile),
        ellipsis: true,
        width:180,
        render: (phone) => (
          <PhoneNumberDisplay value={phone} />
        ),        
      },
      { 
        title: 'Website', 
        dataIndex: 'website', 
        key: 'website', 
        
        sorter: (a, b) => a.website.localeCompare(b.website),
        ellipsis: true,
      },
      {
        title: 'Active',
        dataIndex: 'is_active',
        key: 'is_active',
        width:80,
        render: (isActive) => (
          <div className="fw fl tc">
            { isActive ? 
            <CheckCircleOutlined style={{ color: 'green' }} /> : 
            <CloseCircleOutlined style={{ color: 'red' }} />
            }
          </div>
        ),
        sorter: (a, b) => a.is_active - b.is_active,
        sortDirections: ['descend', 'ascend'],
      },    
      {
        title: 'Verify',
        dataIndex: 'is_verified',
        key: 'is_verified',
        width:80,
        render: (isActive) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            { isActive &&
                <img src={verifiedLogo} alt="Verified" className='w20 h20' /> 
            }
          </div>
        ),
        sorter: (a, b) => a.is_verified - b.is_verified,
        sortDirections: ['descend', 'ascend'],
      }
      ];

  return columns;


}

export default TableColumnTwo;






