import { PhoneNumberUtil } from 'google-libphonenumber'
const phoneNumberUtil = PhoneNumberUtil.getInstance()

const validateNumber = (number) => {
    try {
      const parsedNumber = phoneNumberUtil.parse(number);
      const regionCode = phoneNumberUtil.getRegionCodeForNumber(parsedNumber);
      return phoneNumberUtil.isValidNumberForRegion(parsedNumber, regionCode);
    } catch {
      return false;
    }
}

export function PhoneNumberValidateOne(moduleName) {
    const errors = [];

    if (!validateNumber(moduleName.phone_1)) {
        errors.push('Phone 1 is invalid');
    }

    if (moduleName.phone_2.length > 3) {
        if (!validateNumber(moduleName.phone_2)) {
            errors.push('Phone 2 is invalid.');
        }
    }

    if (moduleName.phone_3.length > 3) {
        if (!validateNumber(moduleName.phone_3)) {
            errors.push('Phone 3 is invalid.');
        }
    }

    if (moduleName.phone_4.length > 3) {
        if (!validateNumber(moduleName.phone_4)) {
            errors.push('Phone 4 is invalid.');
        }
    }

    return errors;
}  

export function PhoneNumberValidateTwo(moduleName) {
    const errors = [];

    if (!validateNumber(moduleName.phone_mobile)) {
        errors.push('Phone mobile is invalid');
    }

    if (moduleName.phone_other.length > 3) {
        if (!validateNumber(moduleName.phone_other)) {
            errors.push('Phone Other is invalid.');
        }
    }

    return errors;
}  


