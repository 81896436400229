import React from "react";

const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <>

      <div className="fw fl">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="fw fl tc">
                  <p className="mt10 mb10 p0 ">&copy;  {currentYear} 1db.ca. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Footer;
