import React from 'react';

function RadioButton({name, value, checked, onChange }) {
  return (
    <label className="radiocontainer">
      {value === "true" ? 'YES' : 'NO'}
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="radiocheckmark"></span>
    </label>
  );
}

export default RadioButton;
