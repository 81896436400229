import React from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const PhoneNumberDisplay = ({ value, ...props }) => {
  return (
    <PhoneInput
      value={value ?? ""}
      disabled 
      {...props}
    />
  );
};

export default PhoneNumberDisplay;